/*
 * @Description:
 * @Version:
 * @Author: 陈莉莉
 * @Date: 2022-01-05 09:56:50
 */
import {axios} from '@/utils/request'

let OSS = require('ali-oss')
let client = new OSS({
  accessKeyId: 'LTAI5tDt9itRezjnJ8FDVNfA',
  accessKeySecret: 'Ji7k6OaPsHK2Pa8BzTr1j7q0d8lp3B',
  region: 'oss-cn-hangzhou',
  authorizationV4: true,
  bucket: 'dianqixiaoshangcheng2',
  endpoint: 'https://file.ahzehui.cn',
  cname: true
})
// oss分片上传
export const uploadFile = async (ObjName, fileUrl, progressFun) => {
  ObjName = parseInt(Math.random() * 100000 + 1, 10) + '.' + ObjName.split('.')[ObjName.split('.').length - 1]
  console.log(ObjName)
  try {
    var result = null
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    let day = new Date().getDate()
    let time = new Date().getTime()
    result = await client.multipartUpload(`${year}${month}${day}${time}${ObjName}`, fileUrl, progressFun)
    console.log('result', result)
    return result
  } catch (e) {
    console.log(e)
  }
}

// 暂停上传
export function cancelApi() {
  return client.cancel()
}
